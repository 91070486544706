import React from "react";
import "./cart-dropdown.styles.scss";
import { connect } from "react-redux";

// import CustomButton from "../custom-button/custom-button.component";
import CartItem from "../cart-item/cart-item.component";

import { selectCartItems } from "../../redux/cart/cart.selectors";
import {toggleCartHidden} from '../../redux/cart/cart.actions';
import { createStructuredSelector } from "reselect";

import { Link } from "react-router-dom";

const CartDropdown = ({cartItems, dispatch}) => (
    <div className="cart-dropdown">
        <div className="cart-items">
            {/* {
                cartItems.map(cartItem => <CartItem key={cartItem.id} item={cartItem}/>)
            } */}
            {
                cartItems.length
                ? ( 
                    cartItems.map(cartItem => <CartItem key={cartItem.id} item={cartItem}/>) 
                )
                : (
                    <span className='empty-message'>You cart is empty</span>
                )
            }
        </div>
        <Link 
            to='/checkout' 
            className='checkout-button' 
            onClick={() => dispatch(toggleCartHidden())}>GO TO CHECKOUT</Link>
    </div>
);

// const mapStateToProps = ({cart: {cartItems}}) => ({
//     cartItems
// });

// const mapStateToProps = state => ({
//     cartItems: selectCartItems(state)
// })
const mapStateToProps = createStructuredSelector({
    cartItems: selectCartItems
});

export default connect(mapStateToProps)(CartDropdown);