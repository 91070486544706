const SHOP_DATA = [
  {
    id: 1,
    title: 'Gis & Kimonos',
    routeName: 'gis-kimonos',
    items: [
      {
        id: 1,
        name: 'Venum Challenger Karate Gi',
        imageUrl: 'https://asia.venum.com/media/catalog/product/cache/3d6373dfdbd8bf7042581a31874a0831/d/e/de70ce76d94e90fe540860aa673f7505300b23df_bjj_challenger_white_1500_00a.jpg',
        price: 90
      },
      {
        id: 2,
        name: 'Hayabusa Lightweight Gi (WKF approved)',
        imageUrl: 'https://cdn.webshopapp.com/shops/16221/files/361607255/800x1067x3/hayabusa-hayabusa-winged-lightweight-karate-gi-whi.jpg',
        price: 75
      },
      {
        id: 3,
        name: 'Venum Absolute Karate Gi',
        imageUrl: 'https://asia.venum.com/media/catalog/product/cache/c528650dd2c7151d7b05f5274b7be224/e/u/eu-venum-1274-150cm-eu-venum-1274-150cm-galery_image_1-bjj_absolute_white_1500_00a_1.jpg',
        price: 69.95
      },
      {
        id: 4,
        name: 'Shureido New Wave Karate Gi',
        imageUrl: 'https://hajimekarate.com/wp-content/uploads/2018/09/nw3wkf1.jpg',
        price: 120
      },
      {
        id: 5,
        name: 'Venum Elite Karate Gi',
        imageUrl: 'https://cdn.webshopapp.com/shops/16221/files/45444754/800x1067x3/venum-venum-elite-kata-karate-gi-white.jpg',
        price: 130.79
      },
      {
        id: 6,
        name: 'Adidas Adifit Kata Karate Gi',
        imageUrl: 'https://www.cimac.net/media/catalog/product/cache/f8204821c80645e7b849efd226887134/k/1/k191_plain.jpg',
        price: 120.59
      }
    ]
  },
  {
    id: 2,
    title: 'Gloves',
    routeName: 'gloves',
    items: [
      {
        id: 7,
        name: 'Venum MMA Challenger Gloves',
        imageUrl: 'https://euro.venum.com/media/catalog/product/cache/3d6373dfdbd8bf7042581a31874a0831/c/2/c2f85c0f6c1ef8bdd7b82dbeeb77c9db32a85f7c_MMA_GLOVES_CHALLENGER_BLACK_BLACK_HD_01.jpg',
        price: 44
      },
      {
        id: 8,
        name: 'Leone Contact MMA Gloves',
        imageUrl: 'https://www.leone1947.com/cache/media/2020/08/gp095_01_1_1146.jpg/f7ff4d303ef7aae826a82afd21082120.jpg',
        price: 37.95
      },
      {
        id: 9,
        name: 'UFC Official Figting Gloves',
        imageUrl: 'https://resources.sport-tiedje.com/bilder/ufc/ufc-official-pro-fight-mma-handschuhe/ufc-official-pro-fight-mma-handschuhe_1_600.jpg',
        price: 98.95
      },
      {
        id: 10,
        name: 'Everlast Pro Boxing Gloves',
        imageUrl: 'https://images.sportsdirect.com/images/products/79154008_l.jpg',
        price: 87.95
      }
    ]
  },
  {
    id: 3,
    title: 'Weapons',
    routeName: 'weapons',
    items: [
      {
        id: 11,
        name: 'Hardrubber Nunchaku (Black) by Dragon Sports',
        imageUrl: 'https://www.dragonsports.eu/110821-verylarge_default/nunchaku-hard-rubber.jpg',
        price: 35.99
      },
      {
        id: 12,
        name: 'Kansa Nunchaku (Light wood) by Dragon Sports',
        imageUrl: 'https://www.dragonsports.eu/520781-verylarge_default/nunchaku-advanced-level-wood-chain-kansa.jpg',
        price: 25.99
      },
      {
        id: 13,
        name: 'Rubber Nunchaku WNA (White/Black)',
        imageUrl: 'https://www.nunchaku-shop.com/wp-content/uploads/2018/12/nunchaku-traditional-wit-senior.jpg',
        price: 22.50
      },
      {
        id: 14,
        name: 'Rubber Nunchaku WNA (Pink/Black)',
        imageUrl: 'https://www.nunchaku-shop.com/wp-content/uploads/2018/12/nunchaku-professional-roze-senior.jpg',
        price: 22.50
      },
      {
        id: 15,
        name: 'Rubber Nunchaku WNA (Yellow/Black)',
        imageUrl: 'https://www.nunchaku-shop.com/wp-content/uploads/2018/11/nunchaku-traditional-geel-junior.jpg',
        price: 22.50
      }
    ]
  },
  {
    id: 4,
    title: 'Belts',
    routeName: 'belts',
    items: [
      {
        id: 16,
        name: 'Hayashi Belts',
        imageUrl: 'https://cdn.budoland.com/media/catalog/product/cache/55aa34a7c50d18e5c7c0211402fd318a/h/a/hayashi-martial-arts-belt-050_6.jpg',
        price: 5.12
      }
    ]
  },
  {
    id: 5,
    title: 'Bags & Pads',
    routeName: 'bags-pads',
    items: [
      {
        id: 17,
        name: 'Everlast Boxing Bag Classic',
        imageUrl: 'https://i5.walmartimages.com/asr/3cb4175f-0e10-4e24-9a18-34ba8a5a3d2e_1.5c517ca12b1d72aa75ea3feeccb38159.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF',
        price: 150.55
      },
      {
        id: 18,
        name: 'Venum Origins Boxing Bag',
        imageUrl: 'https://euro.venum.com/media/catalog/product/cache/3d6373dfdbd8bf7042581a31874a0831/7/1/7172a7f60d871e30f38f809aee4f5d0d2351391a_5J5A9455.jpg',
        price: 180.99
      },
      {
        id: 19,
        name: 'Outshock Boxing Bag',
        imageUrl: 'https://cdn.shopify.com/s/files/1/1330/6287/products/TB_20850_208365166_20TCI_20PSHOT_20001_20_7C_20PSHOT_6ea3b0db-5e8f-4db0-aa4b-043949358f08_675x.progressive.jpg?v=1621411969',
        price: 59
      },
      {
        id: 20,
        name: 'Leone Punching Bag (Red/White)',
        imageUrl: 'https://www.barbariansfightwear.com/2440/leone-1947-punching-bag.jpg',
        price: 101
      },
      {
        id: 21,
        name: 'Leone Heavy Boxing Bag (White)',
        imageUrl: 'https://www.barbariansfightwear.com/2445/leone-1947-heavy-bag-king-size-60kg-white-red.jpg',
        price: 240
      }
    ]
  }
];

export default SHOP_DATA;
