import './App.css';

import HomePage from './pages/homepage/homepage.component'
import ShopPage from './pages/shop/shop.component';
import AuthPage from './pages/auth/auth.component';
import CheckoutPage from './pages/checkout/checkout.component';
import Header from './components/header/header.component';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import { connect } from 'react-redux';
import { setCurrentUser } from './redux/user/user.actions';
import React from 'react';
import Parse from 'parse';

import { createStructuredSelector } from 'reselect';
import {selectCurrentUser} from './redux/user/user.selectors';

class App extends React.Component {


  componentDidMount() {
    Parse.User.currentAsync().then(user => {
      console.log(user);
      this.props.setCurrentUser(user);
    });
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <Header/>
          <Routes>
          <Route path='/' element={<HomePage/>}/>
          <Route path='/shop/*' element={<ShopPage/>}/>
          <Route path='/checkout' element={<CheckoutPage/>}/>
          <Route path='/login' element={
            this.props.currentUser
            ? <Navigate to='/'/>
            : <AuthPage/>
          } />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
});

// const mapStateToProps = ({user}) => ({
//   currentUser: user.currentUser
// })
// const mapStateToProps = (state) => ({
//   currentUser: selectCurrentUser
// });

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
