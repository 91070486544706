const INITIAL_STATE = {
    sections: [
        {
          title: 'kimonos',
          imageUrl: 'https://images.pexels.com/photos/8042012/pexels-photo-8042012.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          id: 1,
          linkUrl: 'shop/1'
        },
        {
          title: 'gloves',
          imageUrl: 'https://images.pexels.com/photos/6739946/pexels-photo-6739946.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          id: 2,
          linkUrl: 'shop/2'
        },
        {
          title: 'weapons',
          imageUrl: 'https://images.pexels.com/photos/7045445/pexels-photo-7045445.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          id: 3,
          linkUrl: 'shop/3'
        },
        {
          title: 'belts',
          imageUrl: 'https://images.pexels.com/photos/8611964/pexels-photo-8611964.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          size: 'large',
          id: 4,
          linkUrl: 'shop/4'
        },
        {
          title: 'bags and pads',
          imageUrl: 'https://images.pexels.com/photos/260447/pexels-photo-260447.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          size: 'large',
          id: 5,
          linkUrl: 'shop/5'
        }
      ]
}

const DirectoryReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        default:
            return state;
    }
}

export default DirectoryReducer;