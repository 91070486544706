export const addItemToCart = (cart, newItem) => {
    const doesExist = cart.find(
        i => i.id === newItem.id
    )
    if (doesExist) {
        return cart.map(i => 
        i.id === newItem.id
        ? {...i, quantity: i.quantity + 1}
        : i)
    } else {
        return [...cart, {...newItem, quantity: 1}]
    }
}

export const remoteItemFromCart = (cart, itemToRemove) => {
    const existingItem = cart.find(item => item.id === itemToRemove.id)
    if (existingItem.quantity === 1) {
        return cart.filter(item => item.id !== itemToRemove.id)
    } else {
        return cart.map(item => 
            item.id === itemToRemove.id
            ? {...item, quantity: item.quantity - 1}
            : item
        )
    }
}