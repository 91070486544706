import { TextField } from "@mui/material";
import React from "react";
import './form-input.styles.scss';

const FormInput = ({handleChange, label, ...otherProps}) => (
    <div className='group'>
        {/* <label className='form-input-label'>{label}</label> */}
        {/* <input className='form-input' onChange={handleChange} {...otherProps}/> */}
        <TextField label={label} fullWidth onChange={handleChange} {...otherProps}/>
    </div>
);

export default FormInput;