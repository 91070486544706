import React from "react";
import "./checkout-item.styles.scss";

import { connect } from "react-redux";
import { clearItemFromCart, addItem, removeItem } from "../../redux/cart/cart.actions";

const CheckoutItem = ({cartItem, clearItemFromCart, addItem, removeItem}) => (
    <div className='checkout-item'>
        <div className='image-container'>
            <img src={cartItem.imageUrl} alt='item'/>
        </div>
        <span className='name'>{cartItem.name}</span>
        <span className='quantity'>
            <div className="arrow" onClick={() => removeItem(cartItem)}>&#10134;</div>
            <div className="value">{cartItem.quantity}</div>
            <div className="arrow" onClick={() => addItem(cartItem)}>&#10133;</div>
        </span>
        <span className='price'>&euro; {cartItem.price}</span>
        <div className='remove-button' onClick={() => clearItemFromCart(cartItem)}>&#10005;</div>
    </div>
);

const mapDispatchToProps = dispatch => ({
    clearItemFromCart: item => dispatch(clearItemFromCart(item)),
    addItem: item => dispatch(addItem(item)),
    removeItem: item => dispatch(removeItem(item))
});

export default connect(null, mapDispatchToProps)(CheckoutItem);