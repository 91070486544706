import React from "react";

import Login from "../../components/login/login.component";
import { Grid } from "@mui/material";
import Signup from "../../components/signup/signup.component";

const AuthPage = () => (
    <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
            <Login/>
        </Grid>
        <Grid item xs={12} md={6}>
            <Signup/>
        </Grid>
    </Grid>
)

export default AuthPage;