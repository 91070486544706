import React from "react";
import { Button } from "@mui/material";

const CustomButton = ({children, ...otherProps}) => (
    // <button {...otherProps}>
    //     {children}
    // </button>
    <Button className="custom-button" variant="contained" {...otherProps}>{children}</Button>
);

export default CustomButton;