import React from "react";
import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";
import Parse from "parse";

class Signup extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            username: ''
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        const {email, password, username} = this.state;
        const user = new Parse.User();
        user.set("username", username);
        user.set("email", email);
        user.set("password", password);
        user.signUp().then(r => {
            console.log(r);
        })
    }

    handleChange = e => {
        const {value, name} = e.target;
        this.setState({[name]: value});
    }

    render() {
        return (
            <div>
                <h2>I do not have an account yet</h2>
                <span>Sign up with your email and password</span>

                <form onSubmit={this.handleSubmit}>
                    <FormInput name="username" type="text" handleChange={this.handleChange} value={this.state.username} label="Username"/>
                    <FormInput name="email" type="email" handleChange={this.handleChange} value={this.state.email} label="Email address"/>
                    <FormInput name="password" type="password" handleChange={this.handleChange} value={this.state.password} label="Password"/>
                    <CustomButton type="submit">Create new account</CustomButton>
                </form>
            </div>
        );
    }
}

export default Signup;