import React from "react";
import './menu-item.styles.scss';

import { Link } from "react-router-dom";

const MenuItem = ({title, imageUrl, linkUrl}) => (
    <div 
        className="menu-item">
        <div className="background-image" style={{
            backgroundImage: `url(${imageUrl})`
        }}></div>
        <div className="content">
            <h1 className="title">{title.toUpperCase()}</h1>
            <span className="subtitle">
                <Link to={linkUrl}>SHOP NOW</Link>
            </span>
        </div>
    </div>
);

export default MenuItem;