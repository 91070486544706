import React from "react";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";

import { connect } from 'react-redux';
import { setCurrentUser } from "../../redux/user/user.actions";
import Parse from "parse";

class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: ''
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        const {username, password} = this.state;
        Parse.User.logIn(username, password).then(r => {
            console.log(r);
            this.props.setCurrentUser(r);
        });
        this.setState({username: '', password: ''});
    }

    handleChange = e => {
        const {value, name} = e.target;
        this.setState({[name]: value});
    }

    // handleLogin = e => {
    //     const {username, password} = this.state;
    //     Parse.User.logIn(username, password).then(r => console.log(r));
    // }

    render() {
        return (
            <div className="log-in">
                <h2>I already have an account</h2>
                <p>Log in with an email address and a password</p>

                <form onSubmit={this.handleSubmit}>
                    {/* <label>Email</label>
                    <input type="email" name="email" onChange={this.handleChange} value={this.state.email} required/>

                    <label>Password</label>
                    <input type="password" name="password" onChange={this.handleChange} value={this.state.password} required/> */}
                    <FormInput name='username' type='text' handleChange={this.handleChange} value={this.state.username} label='UserName'/>
                    <FormInput name='password' type='password' handleChange={this.handleChange} value={this.state.password} label='Password'/>
                    {/* <input type="submit" value="Log in" /> */}
                    <CustomButton type='submit'>Log in</CustomButton>
                </form>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user))
});


export default connect(null, mapDispatchToProps)(Login);