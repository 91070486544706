import CartActionsTypes from './cart.types'
import { addItemToCart, remoteItemFromCart } from './cart.utils'

const INITIAL_STATE = {
    hidden: true,
    cartItems: []
}

const CartReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case CartActionsTypes.TOGGLE_CART_HIDDEN:
            return {
                ...state,
                hidden: !state.hidden
            }
        case CartActionsTypes.ADD_ITEM:
            return {
                ...state,
                cartItems: addItemToCart(state.cartItems, action.payload)
            }
        case CartActionsTypes.CLEAR_ITEM_FROM_CART:
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item.id !== action.payload.id)
            }
        case CartActionsTypes.REMOVE_ITEM:
            return {
                ...state,
                cartItems: remoteItemFromCart(state.cartItems, action.payload)
            }
        default:
            return state;
    }
}

export default CartReducer;