import React from "react";
import { Route, Routes } from "react-router";
import CollectionOverview from '../../components/collection-overview/collection-overview.component';
import CategoryPage from "../category/category.component";

const ShopPage = () => (
    <div className='shop-page'>
        <Routes>
            <Route path='' element={<CollectionOverview/>}/>
            <Route path=':categoryId' element={<CategoryPage/>}/>
        </Routes>
    </div>
);

export default ShopPage;