import React from "react";
import './header.styles.scss';

import { Link } from "react-router-dom";
import {ReactComponent as Logo} from '../assets/logo.svg';

import { connect } from "react-redux";

import Parse from "parse";

import CartIcon from "../cart-icon/cart-icon.component";
import CartDropdown from "../cart-dropdown/card-dropdown.component";

import { createStructuredSelector } from "reselect";
import { selectCartHidden } from "../../redux/cart/cart.selectors";
import { selectCurrentUser } from "../../redux/user/user.selectors";

const Header = ({currentUser, hidden}) => (
    <div className='header'>
        <Link className='logo-container' to='/'>
            <Logo className='logo'/>
        </Link>
        <div className='options'>
            <Link className='option' to='/shop'>Shop</Link>
            <Link className='option' to='/shop'>Contact</Link>
            {
                currentUser
                ? <div className='option' onClick={() => {
                    Parse.User.logOut();
                }}> SIGN OUT</div>
                : <Link className="option" to='/login'>Login</Link>
            }
            <CartIcon/>
        </div>
        {
            hidden ? null : <CartDropdown/>
        }
    </div>
);

// const mapStateToProps = ({user: {currentUser}, cart: {hidden}}) => ({
//     currentUser,
//     hidden
// });


//  const mapStateToProps = (state) => ({
//      currentUser: selectCurrentUser(state),
//      hidden: selectCartHidden(state)
//  });  

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    hidden: selectCartHidden
})

export default connect(mapStateToProps)(Header);