import React from "react";
import "./category.styles.scss";
import CollectionItem from "../../components/collection-item/collection-item.component";

import {selectCollection} from '../../redux/shop/shop.selectors';

import { useSelector } from "react-redux";
import { useParams } from "react-router";

const CategoryPage = () => {
    const {categoryId} = useParams();
    const {title, items} = useSelector(selectCollection(parseInt(categoryId)))
    return (
        <div className='collection-page'>
            <h2 className='title'>{title}</h2>
            <div className='items'>
                {
                    items.map(item => <CollectionItem key={item.id} item={item}/>)
                }
            </div>
        </div>
    );
}

// const mapStateToProps = (state, ownParams) => ({
//     collection: selectCollection(1)(state)
// });

// export default connect(mapStateToProps)(CategoryPage);
export default CategoryPage;