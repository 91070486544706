import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Parse from 'parse';
import {Provider} from 'react-redux';

import {PersistGate} from 'redux-persist/integration/react';

import {store, persistor} from './redux/store';

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App/>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
